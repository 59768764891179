.Clock {
  background-color: white;
  width: 180px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 4px;
  border: 2px solid rgba(0, 0, 0, 0.25);
}

.Clock .Clock-digits {
  font-size: 22px;
  font-weight: bold;
}
