.onyx-live-toggle-wrapper {
  position: absolute;
  right: 30px;
  bottom: 25px;
  display: flex;
}

.onyx-live-toggle {
  background-color: blue;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 0 4px 4px rgba(240, 220, 42, 0.25);
  margin-right: 20px;
}

.onyx-live-toggle-1 {
  background-color: black;
}

.onyx-live-toggle-3 {
  background-color: green;
}
