@font-face {
  font-family: "FuturaPT";
  src: local("FuturaPT"),
    url(/fonts/FuturaPT/FuturaPTBook.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "FuturaPT", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  width: 100%;
  height: calc(100vh - 120px);
}

.onyx-video-player {
  position: relative;
  width: 100%;
  height: 100%;
}

.onyx-sponsor-logo {
  width: 220px;
  padding: 20px;
  border-right-width: 3px;
  border-right-style: solid;
  display: flex;
  align-items: center;
}

.main-header {
  min-height: 50px;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: white;
  border-bottom: 1px solid gray;
}

.main-logo {
  width: 35px;
  height: 35px;
}

.header-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-left-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-menu-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  height: 50px;
}

.header-right-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.side-menu-container {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-right: 50px;
  height: 50px;
}

.search-icon {
  width: 13px;
  height: 13px;
}

.menu-item {
  margin-right: 30px;
  height: 100%;
}

.menu-link {
  position: relative;
  cursor: pointer;
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #e4ec1c;
  bottom: 0;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.menu-link:hover::after {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.header-logo-wrapper {
  margin-right: 30px;
}

.page-main {
  background-color: #0c161c;
  padding: 25px 0;
}

.video-container-title {
  margin: 0;
  color: white;
  margin-bottom: 20px;
}

.competitions-item-image {
  width: 400px;
  height: auto;
  margin-bottom: 8px;
}

.competitions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.competitons-item-link {
  display: block;
  transition: all 1s ease-out;
}

.competitons-item-link-wrapper {
  width: fit-content;
  padding: 40px 0;
  padding-top: 20px;
}

.competitons-item-link:hover {
  transform: scale(1.1);
  transition: all 1s ease-out;
}

.features-item-link {
  display: block;
  cursor: pointer;
  transition: all 1s ease-out;
}

.features-item-link:hover {
  transform: scale(1.1);
  transition: all 1s ease-out;
}

.competition-item-title {
  font-size: 15px;
  color: white;
  margin: 0;
  padding: 0;
}

.competitions-subtitle {
  font-size: 13px;
  color: white;
}

.competitons-item-link {
  cursor: pointer;
}

.competitions-image-wrapper {
  width: 180px;
  height: 180px;
  margin-bottom: 8px;
}

.features-item-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.arrow-down {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.menu-link--arrow {
  position: relative;
  padding-right: 16px;
}

.menu-link--arrow:before {
  content: "";
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 0;
  top: 19px;
}

.side-menu-list {
  opacity: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 71px;
  bottom: -59px;
  padding-top: 10px;
  transition: all 0.5s ease-out;
}

.menu-link--right:hover .side-menu-list {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.menu-link--sports:hover .side-menu-list {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.video-container {
  margin-bottom: 40px;
  padding: 0 20px;
}

.features-container {
  padding: 0 20px;
}

.competitions-container {
  padding: 0 20px;
}

.competitions-container .video-container-title {
  margin-bottom: 0;
}

.features-item-link-wrapper {
  width: fit-content;
  padding: 40px 0;
  padding-top: 0px;
}

.competitions-container .swiper-button-next,
.swiper-button-prev {
  color: #e4ec1c;
}

.features-container .swiper-button-next,
.swiper-button-prev {
  color: #e4ec1c;
}

.welcome-screen {
  width: 100%;
  height: auto;
}

.logo-wrapper {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 100;
}

.logo-wrapper img {
  width: 58px;
  object-fit: contain;
}

.arrow-wrapper {
  width: 19px;
  height: 13px;
  position: absolute;
  top: 28px;
  right: -28px;
}

.switch-button {
  width: 100px;
  height: 20px;
  border-radius: 8;
  background-color: #d94b6d;
  display: block;
  border-radius: 10px;
  margin-bottom: 5px;
}

.switch-button--green {
  background-color: #00f981;
}

.page-main-video-intro {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
